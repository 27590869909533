export const haircuts = [
  {
    name: "Snit & Baard",
    amount: "30",
    time: "45",
    link: 'https://booking.setmore.com/scheduleappointment/d0ab7a22-f7da-4283-945f-566ae204c40d/services/ac2d7fc5-2be6-4a7a-ba17-0276932f0f51?source=settings'
  },
  {
    name: "Mannen snit",
    amount:"20",
    time: "35",
    description: "snit & wassen",
    link: 'https://booking.setmore.com/scheduleappointment/d0ab7a22-f7da-4283-945f-566ae204c40d/services/s12b95429f6d8899ee4a85ea87322604fce48cc4d?source=settings',
  },
  {
    name: "Kinderen tot 12 jaar",
    amount:"15",
    time: "30",
    link: 'https://booking.setmore.com/scheduleappointment/d0ab7a22-f7da-4283-945f-566ae204c40d/services/01b4bcc3-39a7-483b-8bed-e7dd1530f9f5?source=settings',
  },
  {
    name: "MALINOV LUXE",
    amount: "40",
    time: "50",
    description: 'snit / baard / wassen / gezichtswax / gezichtsmasker',
    link: 'https://booking.setmore.com/scheduleappointment/d0ab7a22-f7da-4283-945f-566ae204c40d/services/4bd34f76-73dd-458f-bd27-0511b4cc5db6?source=settings',
  },
  {
    name: "Baard trimmen en Aflijnen",
    amount: "15",
    time: "30",
    link: 'https://booking.setmore.com/scheduleappointment/d0ab7a22-f7da-4283-945f-566ae204c40d/services/s7bb13681fd787216994d4ede82c89fcb933cfb51?source=settings',
  },
  {
    name: "Scheren",
    amount: "15",
    time: "15",
    link: 'https://booking.setmore.com/scheduleappointment/d0ab7a22-f7da-4283-945f-566ae204c40d/services/sb67b6069800a0f71ded76e48fc0ddb6f5a06c88d?source=settings',
  },
  {
    name: "Zwart masker",
    amount: "5",
    time: "30",
    link: 'https://booking.setmore.com/scheduleappointment/d0ab7a22-f7da-4283-945f-566ae204c40d/services/6e8afdce-861f-471d-a51e-1ff414d5552a?source=settings',
  },
  {
    name: "Kill Mask",
    amount:"5",
    time: "10",
    link: 'https://booking.setmore.com/scheduleappointment/d0ab7a22-f7da-4283-945f-566ae204c40d/services/647c673c-50d0-4db1-934a-878a3a380c70?source=settings',
  },
  {
    name: "Gezicht wax",
    amount: "3",
    time: "10",
    link: 'https://booking.setmore.com/scheduleappointment/d0ab7a22-f7da-4283-945f-566ae204c40d/services/e7110f22-7d5e-4c86-9f3a-cde3d380cec0?source=settings',
  },
  {
    name: "Gezichtsverzorging",
    amount: "20",
    time: "20",
    description: 'facial steamer',
    link: 'https://booking.setmore.com/scheduleappointment/d0ab7a22-f7da-4283-945f-566ae204c40d/services/a5ffdb57-d0e9-4394-a55f-0d8a4e524208?source=settings',
  },
]